.dashboardView {
	.layout-row {
		display: flex;
		flex-direction: row;
		ms-flex-direction: row;
	}

	.flex {
		-webkit-box-flex: 1;
		-ms-flex: 1 1;
		flex: 1 1;
	}

	.screenerRightBar {
		width: 4vw;
		.sticky {
			width: 4vw;
		}
		.crossIcon {
			font-size: 24px;
			width: 24px;
			margin: 30px auto;
			display: block;
		}

		.iconLists {
			li {
				list-style-type: none;
				margin-bottom: 20px;
				a {
					width: 2vw;
					height: 2vw;
					font-size: 1.1vw;
					margin: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 8px;
					&:hover {
						background-color: $hover;
					}
				}

				a.active {
					background-color: $primary-color;
					color: #fff;
				}
			}
		}
	}

	#aside {
		z-index: 9;
		// width: 4.3vw;
		width: 100%;
		max-width: 5rem;
		position: relative;
		flex-shrink: 0;
		box-shadow: -4px 0px 10px rgb(0, 0, 0, 0.5);

		.logoWrapper {
			background-color: #e6e3e3;
			padding: 15px;

			img {
				width: 2.5vw;
			}
		}

		.navigation {
			li {
				a {
					text-align: center;
					display: flex !important;
					color: #000;
					font-size: 1.1vw;
					display: block;
					width: 2vw;
					height: 2vw;
					margin: auto;
					border-radius: 5px;
					line-height: 46px;
					margin-top: 10px;
					margin-bottom: 10px;
					align-items: center;
					justify-content: center;
					&:hover {
						color: $black;
						background-color: $hover;
					}
					.userProfileImage {
						width: 1.5vw;
						height: 1.5vw;
						border-radius: 50%;
					}

					.customIcons {
						font-size: 1.1vw;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.rotate90Anticlock {
						transform: rotate(-90deg);
					}
				}

				a.active {
					background: $primary_color;
					color: $white;
				}

				.bellIcons {
					position: relative;
					&:before {
						content: "";
						color: #fff;
						width: 15px;
						height: 15px;
						background-color: red;
						border-radius: 50%;
						font-size: 9px;
						display: block;
						text-align: center;
						padding: 3px 0;
						font-weight: 500;
						position: absolute;
						right: 2px;
						top: 2px;
					}

					&:after {
						content: attr(data-note-count);
						position: absolute;
						font-size: 8px;
						display: block;
						text-align: center;
						top: -13px;
						right: 7px;
						color: #fff;
					}
				}
			}
		}

		.navigation.fixed {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			width: 5rem;
		}
	}

	.page-sidenav .sticky {
		height: 100vh;
	}

	.sticky {
		position: -webkit-fixed;
		position: fixed;
	}
}

.leftDrawers {
	z-index: 1;
	left: 4.3vw;
	.ant-drawer-content {
		background: #f8f8f8;
	}
	.closeIcon {
		cursor: pointer;
	}

	.updateProfileButtn {
		background-color: #e7e7e7;
		color: #9a9090;
		border: none;
		height: 40px;
		border-radius: 8px;
	}

	.profileInf {
		background-color: $white;
		padding: 10px;
		border-radius: 8px;
		.userImageWrppr {
			width: 4.5vw;
			height: 4.5vw;
			overflow: hidden;
			border-radius: 8px;
			.userImage {
				width: 4.5vw;
				height: 4.5vw;
				object-fit: contain;
			}
		}

		h5 {
			font-size: 1.3vw;
		}

		p {
			font-size: 0.9vw;
		}
	}

	.profileSettingTab {
		border: 1px solid #dcdcdc;
		color: $black;
		height: 2.4vw;
		width: fit-content;
		padding: 0.5vw 2vw;
		border-radius: 8px;
		margin-right: 10px;
		background-color: #fff;
		cursor: pointer;
		font-size: 0.9vw;
	}

	.profileSettingTab.active {
		background-color: #9abcef;
	}

	.additionalDetails {
		background-color: #f8f8f8;
		padding-top: 20px;
		padding-bottom: 20px;
		position: fixed;
		bottom: 0;
		width: 37%;
		padding-right: 30px;
		.idw {
			color: #4c64a3;
			margin-top: 20px;
			font-size: 12px;
			font-weight: 600;
		}

		.ded {
			font-size: 12px;
		}
	}
	.securities {
		.sdsee {
			border-bottom: 1px solid #cdcdcd;
			padding: 20px 0;
			&:last-child {
				border-bottom: transparent;
			}
			.seTitle {
				font-size: 14px;
			}

			.sePara {
				font-size: 12px;
				color: #9b8e8e;
			}
		}
		.securtyBttn {
			background-color: $primary_color;
			border-radius: 8px;
			color: $white;
		}

		.authenticationStatus {
			background-color: #d24b4b;
			color: #fff;
			border-radius: 8px;
			cursor: not-allowed;
		}

		.authenticationStatus.enabled {
			background-color: green;
			color: #fff;
			border-radius: 8px;
			cursor: not-allowed;
		}
	}
}

.notificationTab {
	li {
		list-style-type: none;
		margin-right: 20px;
		display: flex;
		align-items: center;
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 8px;
		span {
			font-size: 12px;
			display: block;
			width: 20px;
			height: 20px;
			background-color: #9abcef;
			border-radius: 50%;
			text-align: center;
			line-height: 20px;
			margin-left: 10px;
		}
	}

	li.active {
		background-color: #9abcef;
	}
}

.markAsAllRead {
	position: absolute;
	bottom: -24px;
	background: #f8f8f8;
	padding: 10px 12px;
	a {
		color: #4074f9 !important;
		font-size: 14px;
	}
}

.settingIcon {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 27px;
	margin-left: auto;
	text-align: center;
	background: #dedede;
	margin-right: 5px;
}

.settingIcon.active {
	background: #9abcef;
}

.preferenceSelect {
	.ant-select-selector {
		border-radius: 8px !important;
	}
}

.personalDetails {
	p {
		font-size: 0.9vw;
	}
}

.scrollingSv {
	max-height: calc(100vh - 130px);
	overflow-x: hidden;
	overflow-y: auto;
}
