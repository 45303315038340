// .dashboardView{
//     background-color: #F5F0F0;
// }

.broker-flex {
	display: flex;
}
.brocker__heading {
	margin: 36px 0px 0px 100px;
}
.brocker__body {
	display: flex;
	flex-direction: column;
	margin: 0px 75px 0px 130px;
}
.brocker__subheading {
	color: #959494;
	font-weight: 400;
	line-height: 40px;
	font-size: 20px;
	margin-bottom: 4px;
}

.brockers__list {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 35px;
}

.brockerCard__body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 14px 6px;
	// width: 200px;
	border-radius: 20px;
	background-color: white;
	// margin-right: 20px;
	.brocker__img {
		padding-bottom: 4px;
	}
	.brocker__info {
		text-align: center;
		padding-bottom: 8px;
		min-height: 64px;
		.brocker__name {
			font-weight: bold;
			font-size: medium;
		}
		.brocker__description {
			font-weight: normal;
			font-size: 12px;
			color: #a7a0a0;
		}
	}
	.connect__button {
		.button {
			padding: 2px 20px;
			width: 100%;
			border-radius: 5px;
			background-color: #4074f9;
			color: white;
			border: none;
		}
	}

	.brockerAdd__icon {
		font-size: 100px;
	}
}

.AddNewbrockerCard__body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// width: 186px;
	padding: 10px 6px;
	border-radius: 20px;
	background-color: white;
	// margin-right: 20px;
	background-color: #4074f9;
	.AddNewbrocker__img {
		padding-bottom: 4px;
	}
	.AddNewbrocker__info {
		text-align: center;
		padding-bottom: 8px;
		min-height: 64px;
		.AddNewbrocker__description {
			font-size: 15px;
			color: white;
		}
	}

	.AddNewbrockerAdd__icon {
		color: white;
		font-weight: 100;
		font-size: 126px;
		line-height: 100px;
		padding-bottom: 10px;
	}
}

.noBrockerMessage__container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding: 160px;
	box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.12);
	border-radius: 14px;

	.emptyBrockerList__message {
		color: #afafaf;
		font-weight: 400;
		font-size: 22px;
		line-height: 40px;
	}
}

.BrockerTable__container {
	background-color: white;
	padding: 20px;
	box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.12);
	border-radius: 14px;
}

.view__button {
	color: white;
	background-color: #4074f9;
	padding: 2px 10px;
	border-radius: 4px;
	border: none;
}

.add__button {
	color: white;
	padding: 4px;
	border: none;
	background-color: #4074f9;
	padding: 5px 20px;
	border-radius: 5px;
}

.clear__button {
	color: black;
	padding: 4px;
	border: none;
	background-color: transparent;
	padding: 5px 20px;
	border-radius: 5px;
}

.ant-modal-header {
	padding: 24px 24px;
	color: rgba(0, 0, 0, 0.85);
	background: #fff;
	border-bottom: 1px solid #f0f0f0;
	border-radius: 2px 2px 0 0;
}
.ant-modal-title {
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: bolder;
	font-size: 20px;
	line-height: 30px;
	word-wrap: break-word;
}

.steps__header {
	font-weight: bolder;
}

.steps__container {
	p {
		color: black;
		font-weight: 400;
		font-size: 13px;
		line-height: 40px;
	}
	.sub__info {
		padding-left: 14px;
		line-height: 18px;
	}
	.info__link {
		padding: 18px;

		.infoLink__input {
			width: 20rem;
			border: 1px solid grey;
			border-radius: 4px;
			padding: 3px 10px;
			font-size: 12px;
			margin-left: 8px;
			margin-right: 8px;
		}
	}
}

.input__container {
	display: flex;
	.relative__block__Apikey {
		position: absolute;
		i {
			position: relative;
			right: 20px;
		}
		span {
			font-size: small;
		}
		div {
			margin-top: 6px;
			margin-bottom: 40px;
		}
	}
	.div__margin {
		margin-top: 6px;
		margin-bottom: 40px;
	}
	.relative__block__Apisecret {
		position: relative;
		left: 350px;
	}
	.apiseacre__title {
		font-size: small;
		margin-bottom: 4px;
	}

	.api__input {
		// border: 1px solid gray;
		padding: 8px 10px;
		background: #ffffff;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
		border-radius: 9px;
		border: none;
		width: 20rem;
	}
	.api__input::placeholder {
		color: #c1c1c1;
		font-weight: 400;
		font-size: 10px;
		// line-height: 22px;
	}
}

.display__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 10px;
	padding-bottom: 40px;
	.display__title {
		text-align: center;
		margin-top: 30px;
	}
}
