body {
	padding: 0;
	margin: 0;
	font-family: $inter;
	overflow-x: hidden;
	// background-color: $gray;
}

a {
	text-decoration: none;
}

ul {
	padding: 0;
	margin: 0;
}

p,
* {
	padding: 0;
	margin: 0;
}

.ant-form-item-explain-error {
	color: red;
	font-size: 12px;
	// margin-top: 10px;
}

.backgroundWhite {
	background-color: $white;
}

.padding {
	padding: 2rem;
}

#content {
	.paddingLeft,
	.paddingRight {
		li {
			list-style-type: none;
			margin: 0 3px;
		}

		p {
			font-size: 14px;
			font-weight: 500;
		}
	}
}

.rightContent,
.leftContent {
	background-color: $white;
	padding: 10px;
	font-size: 14px;
	border-radius: 5px;
	font-weight: 500;
}

.paddingLeft {
	// padding-left: 2rem;
}

.paddingRight {
	// padding-right: 2rem;
}

.headingSection {
	.leftArrow {
	}

	.heading {
	}

	.subHead {
	}
}

.allHeading {
	font-size: 1.2vw;
}
