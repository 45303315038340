.accountInfo {
	border: 1px solid $black;
	padding: 11px 20px;
	font-size: 0.9vw;
	border-radius: 10px;
	width: fit-content;

	.anticon-info-circle {
		color: $primary_color;
	}
}

.scout {
	.tableIcons {
		background-color: #eeeded;
		color: #3f3e3e;
	}
}

.stockInfoWrapper {
	width: 1015px;
	background-color: $white;
	margin-top: 10px;
	border-radius: 8px;
	.stockInfo {
		display: flex;
		align-items: center;
		padding: 5px 0px;

		li {
			display: flex;
			align-items: center;
			list-style-type: none;
			padding: 0 15px;
			margin: 5px 0;
			border-right: 1px solid darkgrey;

			&:last-child {
				border-right-color: transparent;
			}

			img {
				width: 30px;
				margin-right: 10px;
			}

			p {
				font-size: 12px;
				font-weight: 500;
				margin-right: 10px;
			}

			p.cmpName {
				font-size: 13px;
				font-weight: 600;
			}

			p.minus {
				color: red;
			}

			p.plus {
				color: #0bda51;
			}
		}
	}
}

.chartTools {
	padding: 2vw;
	border-radius: 8px;
	background-color: $white;
	.toolBoxWrppr {
		cursor: pointer;
		.toolBox {
			background-color: #eae7e7;
			width: 5vw;
			height: 5vw;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 2.5vw;
			margin: auto;
			color: #847d7d;

			&:hover {
				background-color: $hover;
				color: #000;
			}
		}

		p {
			font-size: 0.75vw;
			font-weight: 400;
		}

		.status {
			font-size: 0.6vw;
			display: none;
		}
	}

	.toolBoxWrppr.completed {
		.status {
			color: $primary_color;
			display: block;
		}

		.toolBox {
			background-color: $primary_color;
			color: #fff;
		}
	}

	.toolBoxWrppr.inProgress {
		.status {
			color: #e89f3f;
			display: block;
		}

		.toolBox {
			background-color: #ffe8c9;
			color: #e89f3f;
		}
	}
}

.emptyActivity {
	height: 200px;
	background: $white;
	border-radius: 8px;
	display: table;
	width: 100%;
	text-align: center;

	h4 {
		line-height: 200px;
		color: #bfbfbf;
	}
}

.leftDrawers {
	.leftToolBar {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;

		.toolBoxWrppr {
			cursor: pointer;
			.toolBoxHvr {
				position: relative;
				padding: 5px;
				.toolBox {
					width: 4vw;
					height: 4vw;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #eae7e7;
					position: relative;
					z-index: 1;
					font-size: 1.5vw;
					cursor: pointer;
					color: $black;

					&:hover {
						background-color: $hover;
						color: $black;
					}
				}

				&:after {
					content: "";
					position: absolute;
					width: 65px;
					height: 65px;
					background-color: #eae7e7;
					top: 7%;
					bottom: 0;
					left: 7%;
					right: 0;
					z-index: 0;
					border-radius: 8px;
					transition: all 0.5s;
					transform: scale(1);
					opacity: 0.5;
				}

				&:hover::after {
					transform: scale(1.2);
					background-color: $primary_color;
				}
			}
		}

		.toolBoxWrppr.active {
			.toolBox {
				background-color: $primary_color;
				svg {
					path {
						fill: $white;
					}
				}
			}

			p {
				color: $primary_color;
				font-weight: 600;
			}
		}
	}

	.otherTools {
		padding: 0;
		margin: 0;
		position: absolute;
		bottom: 0;
		width: 92.5%;
		li {
			list-style-type: none;
			cursor: pointer;
			display: flex;
			align-items: center;
			border-radius: 8px;
			border: 1px solid #d9d9d9;
			margin: 5px 0px;
			padding: 2px 20px;
			transition: all 0.5s;
			font-size: 25px;
			&:hover {
				background-color: $hover;
				border-color: $hover;
				color: $black;
				p {
					color: $black;
				}
			}

			img {
				height: 30px;
			}

			p {
				font-size: 0.9vw;
				margin-bottom: 0;
				margin-left: 20px;
			}
		}

		li.active {
			background-color: $primary_color;
			color: #fff;
			border-color: $primary_color;
			p {
				color: #fff;
				font-weight: 600;
			}
		}
	}
}

.emptyScrrn {
	padding: 40px;
	margin-top: 100px;
	background: $white;
	border-radius: 8px;
	.toolIcon {
		font-size: 5vw;
		color: #e2e2e2;
	}

	p {
		font-size: 1.2vw;
		color: #e2e2e2;
		margin-top: 10px;
	}

	button {
		background: $primary_color;
		height: 44px;
		margin-top: 25px;
		width: 15vw;
		border-radius: 8px;
		&:hover,
		&:active,
		&:focus {
			background: $primary_color;
			border-color: $primary_color;
			span {
				color: $white;
			}
		}
		span {
			color: $white;
			font-weight: 600;
			font-size: 0.9vw;
		}
	}
}

.screenerModal {
	.ant-modal-body {
		// padding-left: 0;
		// padding-right: 0;
	}

	.ant-modal {
		width: 45%;
	}
}

.tableIcons {
	width: 35px;
	height: 35px;
	font-size: 20px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: $hover;
		color: $black;
	}
}

.tableIcons.active {
	background-color: $primary-color;
	color: $white;
}

.tableSeartchInputWrapper {
	width: 0;
	transition: width 0.5s;
	overflow: hidden;
	.tableSeartchInput {
		box-shadow: none !important;
		border-radius: 30px;

		&:hover,
		&:focus,
		&:active {
			border-color: $primary-color !important;
			box-shadow: none !important;
			outline: none !important;
		}
	}
}

.tableSeartchInputWrapper.active {
	width: 180px;
}

.scoutModal {
	.scoutActionButton {
		button {
			height: 40px;
			font-size: 0.9vw;
			color: $black;
			background-color: #f4f4f4;
			margin-right: 10px;
			border: transparent;
			border-radius: 8px;
			&:hover {
				background-color: $hover;
			}
		}

		button.active {
			background-color: $primary-color;
			color: $white;
		}
	}

	.addScoutConditions {
		background-color: #f9f9f9;
		height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		.addConditionIcon {
			background-color: $primary-color;
			color: $white;
			width: 40px;
			height: 40px;
			padding: 0;
			border-radius: 8px;
		}

		p {
		}
	}
}

.screenerNameScoutName {
	margin-right: 15px;
	button {
		background: #fff;
		border: 1px solid #a4a4a4;
		color: $black;
		font-size: 16px;
		height: 40px;
		border-radius: 8px;
		position: relative;
		padding-right: 50px;

		.editScoutIcon {
			margin-left: 20px;
			position: absolute;
			height: 39px;
			top: 0;
			padding: 0 10px;
			width: 39px;
			right: 0px;
			border-left: 1px solid #a4a4a4;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: $hover;
			color: $black;
			border-color: $hover;
			.editScoutIcon {
				border-color: $white;
			}
		}
	}

	button.active {
		background: $primary-color;
		span {
			color: $white;
		}

		.editScoutIcon {
			color: $white;
		}
	}
}

.publishBttn,
.publishMore {
	background: #e4e4e4;
	color: $black;
	border: 1px solid #e4e4e4;
	height: 40px;
	padding: 0 30px;
	border-radius: 8px;
	margin-right: 10px;
	&:hover,
	&:active,
	&:focus {
		background: $hover;
		border-color: $hover;
		span {
			color: $black;
		}
	}
}

.scoutsPage {
	.selectedStocks {
		background-color: $white;
	}
}

.scoutEmpty {
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border: 1px solid #e2dddd;
	border-radius: 8px;
	margin-top: 40px;
	text-align: center;
	font-size: 20px;
	color: #afafaf;
}

.transparentLabel {
	.ant-form-item-label {
		label {
			color: transparent;
		}
	}
}

.selectSourceDropdown {
	display: flex;
	align-items: center;
	font-size: 20px;
	padding: 3px 0;
	span {
		margin-left: 10px;
		display: block;
		font-size: 15px;
	}
}

.conditionList {
	margin-top: 20px;
	margin-bottom: 20px;
	max-height: 300px;
	overflow-y: auto;
	p {
		font-size: 15px;
		margin-bottom: 5px;
		padding-left: 50px;
		padding-right: 50px;
		padding-top: 5px;
		padding-bottom: 5px;
		cursor: pointer;
		&:hover {
			background-color: $hover;
		}
	}
	.small {
		font-size: 13px;
		color: #858585;
	}
}

.sourceMenuClass {
	&:hover {
		background-color: $hover;
	}
}

.sourceMenuClass.active {
	background-color: $primary-color;
	color: $white;
}

.conditionsList {
	background-color: #fff;
	padding: 30px 50px;
	border: 1px solid #eae4e4;
	border-radius: 15px;
	margin-top: 40px;
	p {
		font-size: 14px;
		.gray {
			color: #717171;
			margin-left: 15vw;
		}
	}

	.controls {
		display: flex;
		align-items: center;
		border-radius: 8px;
		overflow: hidden;
		li {
			list-style: none;
			margin: 5px 0;
			padding: 6px 12px;
			font-size: 18px;
			color: #a7a7a7;
			cursor: pointer;
			background-color: #f5f5f5;
			&:hover {
				background-color: $hover;
			}
		}

		li.active {
			background-color: $primary-color;
			color: #fff;
		}
	}
}

.inside_drawer {
	// z-index: 9;
	left: 4.3vw;
	.ant-drawer-mask {
		display: none;
	}
}
