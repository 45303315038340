// $enable-important-utilities: false; //this disables !important
// @import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700&family=Inter:wght@100;200;300;400;500;600&display=swap");
@import "./util.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
// Predefined Classes
@import "./mixins.scss";
@import "./veriable.scss";
@import "./variable_v2.scss";

// Components
@import "./components/form.scss";
@import "./components/header.scss";
@import './components/quillEditor.scss';


@import "./style-v1.scss";
// Pages
@import "./pages/theme.scss";
@import "./pages/login.scss";
@import "./pages/report.scss";
@import "./pages/admin.scss";
@import "./pages/home.scss";
@import "./pages/broker.scss";
@import "./pages/scouts.scss";
@import "./pages/theme_v2.scss";
