$primary_color: #4074F9;
$secondary_color: #BEBABA;
$gray: #F5F5F5;
$lightGray: #F2F2F2;
$white: #fff;
$black: #000;
$darkGray: #4D4D4D;
$hover:#C4D5FF;


// Font Families
$inter: 'Inter', sans-serif;
$abhaya: 'Abhaya Libre',
serif;