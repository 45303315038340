.ant-form-item {
	margin-bottom: 16px;
	.ant-form-item-label {
		label {
			color: $black;
			font-size: 14px;
			line-height: 24px;
			font-weight: 400;
			&:before {
				content: "" !important;
			}
		}
	}
}

.form-control {
	font-size: 14px;
	font-weight: 400;
	height: 40px;
	&:focus,
	&:active,
	&:hover {
		border-color: $primary_color;
		box-shadow: none;
		outline: none;
	}
	&::placeholder {
		color: #b5b5b5;
	}
}

.rememberMe {
	span {
		font-size: 12px;
		color: #918c8c;
	}

	span:last-child {
		position: relative;
		top: -2px;
	}
}

.forgotPassword {
	font-size: 12px;
	color: $primary_color;
}

.allBtns {
	width: fit-content;
	height: 40px;
	display: block;
	background-color: $primary_color;
	border-radius: 8px;
	color: $white;
	padding: 7px 30px;
	margin: auto;
	span {
		color: $white;
		font-weight: 400;
	}

	&:hover {
		border-color: $primary-color;
		span {
			color: $primary_color;
		}
	}
}

.flagSelect {
	button {
		padding: 0;
		font-size: 14px !important;
		border: none;
		span {
			color: #918c8c;
		}
	}

	ul {
		div {
			input {
				border: 1px solid #918c8c;
				font-size: 12px;
				border-radius: 8px;
			}
		}

		li {
			.ReactFlagsSelect-module_selectFlag__2q5gC {
			}

			.ReactFlagsSelect-module_label__27pw9 {
				font-size: 14px;
				color: #918c8c;
			}
		}
	}
}

.stockDropdown {
	display: flex;
	li {
		list-style-type: none;
		font-size: 12px;
		border: 1px solid #e6e6e6;
		margin: 0 10px;
		text-align: center;
		border-radius: 13px;
		min-width: 50px;
		padding: 2px 10px;

		&:hover {
			background-color: $hover;
		}
	}

	li.active {
		background-color: $primary-color;
		color: $white;
	}
}

.stocksList {
	.imgWrapper {
		width: 25px;
		height: 25px;
		overflow: hidden;
		background-color: #918c8c;
		border-radius: 50%;
	}

	p {
		font-size: 14px;
	}

	p.lightColored {
		color: #918c8c;
	}

	.stockActions {
		text-align: center;
		a {
			border: 1px solid #918c8c;
			font-size: 14px;
			font-weight: 600;
			padding: 4px 10px;
			border-radius: 8px;
		}

		a.add {
			color: $primary-color;
		}

		a.remove {
			color: red;
		}
	}
}

.stockMenuDropdown {
	.ant-dropdown-menu-item {
		&:hover {
			background-color: transparent;
		}
	}
}

.selectedStocks {
	border: 1px solid #eae4e4;
	box-shadow: none;
	border-radius: 8px;
	.ant-dropdown-menu-item {
		&:hover {
			background-color: transparent;
		}
	}
}

.addScoutBtn {
	background-color: $hover;
	color: $black;
	border-radius: 30px;
	border-color: $hover;
	&:hover {
		background-color: $primary-color;
		color: #fff;
		border-color: $primary-color;
	}
}

// button:disabled {
// 	// background: #D7D7D7 !important;
// 	border: none;
// 	color: $black !important;
// 	span {
// 		color: $black !important;
// 	}
// }
